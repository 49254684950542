import Utils from './Utils';

export default class ShowcaseController {
  constructor(baseUrl) {
    this.DOM = {
      el: document.querySelector('[data-showcase="el"]'),
      itemEls: []
    };

    this.baseUrl = baseUrl;
    this.apiURL = this.baseUrl + '/api/v1/top';
    this.data = {};
    this.currentIndex = 0;
    this.prevIndex = -1;
  }

  init() {
    if (!this.DOM.el) return;
    this.getChildren();
    this.getData();
  }

  getChildren() {
    this.DOM.container = this.DOM.el.querySelector('.g-showcase__list');
  }

  getData() {
    Utils.sendRequest(this.apiURL, xhr => {
      const resp = JSON.parse(xhr.response);

      if (resp.status === 'success') {
        this.data.showcaseItems = resp.fvs;
        this.handleGetData();
      }
    });
  }

  handleGetData() {
    this.data.showcaseItems.forEach((itemUrl, index) => {
      const itemEl = document.createElement('li');
      const imageEl = document.createElement('figure');

      itemEl.classList.add('g-showcase__item');
      imageEl.classList.add('g-showcase__image');

      imageEl.style.backgroundImage = `url(${this.baseUrl + itemUrl})`;
      if (index === 0) {
        itemEl.style.opacity = 1;
      }
      else {
        itemEl.style.opacity = 0;
      }

      itemEl.appendChild(imageEl);
      this.DOM.itemEls.push(itemEl);
      this.DOM.container.appendChild(itemEl);
    });

    setInterval(this.startCycle.bind(this), 3000);
  }

  startCycle() {
    this.prevIndex = this.currentIndex;
    this.currentIndex = (this.currentIndex + 1) % this.DOM.itemEls.length;
    this.DOM.itemEls[this.prevIndex].style.opacity = 0;
    this.DOM.itemEls[this.currentIndex].style.opacity = 1;
  }
}