import Utils from './Utils';
import WorksItem from './WorksItem';

export default class WorksController {
  constructor(baseUrl, page, win, isEN) {
    this.DOM = {
      el: document.querySelector('[data-works="el"]'),
      itemEls: []
    };

    this.baseUrl = baseUrl;
    this.win = win;
    this.isEN = isEN;

    this.page = page;
    this.isIndex = this.page === 'page-index';

    this.workRows = [];
    this.workItems = [];

    this.apiURL = this.isIndex ? this.baseUrl + '/api/v1/top' : this.baseUrl + '/api/v1/works';
    this.data = {};

    this.currentCategory = '';
    this.currentPage = 1;
    this.nextPage = 1;
  }

  init() {
    if (!this.DOM.el) return;
    this.getChildren();
    this.getData();
    this.bindEvents();
  }

  getChildren() {
    this.DOM.container = this.DOM.el.querySelector('.g-works__container');
    this.DOM.moreBtn = document.querySelector('[data-works="btn"]');
    this.DOM.category = document.querySelector('[data-works="category"]');
    this.DOM.all = document.querySelector('[data-works="all"]');
  }

  bindEvents() {
    if (this.DOM.moreBtn) {
      this.DOM.moreBtn.addEventListener('click', this.onMoreClick.bind(this));
    }

    if (this.DOM.category) {
      this.DOM.all.addEventListener('click', this.onCategoryClick.bind(this));

      window.addEventListener('scroll', () => {
        if (window.scrollY >= window.innerHeight) {
          this.DOM.category.classList.remove('is-visible');
          this.DOM.category.classList.add('is-label-visible');
        }
      });

      setTimeout(() => {
        this.DOM.category.classList.add('is-loaded');
      }, 1000);

      const labelEl = this.DOM.category.querySelector('.category__label');
      labelEl.addEventListener('click', e => {
        e.preventDefault();
        this.DOM.category.classList.toggle('is-visible');
      });
    }
  }

  getData() {
    Utils.sendRequest(this.apiURL, xhr => {
      const resp = JSON.parse(xhr.response);

      if (resp.status === 'success' || resp.status === true) {
        this.data = this.isIndex ? resp.pickup : resp.works;
        this.categoryData = !this.isIndex && resp.category;
        this.nextPage = resp.next && resp.next;

        this.handleGetData();

        this.populateCategories();
      }
    });
  }

  handleGetData() {
    this.generateRows();
    this.populateRows();
  }

  generateRows() {
    const numRows = Math.ceil(this.data.length / 2.5);
    // const numRows = Math.floor(this.data.length / 2.5);
    let workRows = [];
    let itemIndex = 0;

    for (let i = 0; i < numRows; i++) {
      if ((i + 1) % 2 === 0) { // even row
        workRows[i] = [];
        this.data[itemIndex + 3] && workRows[i].push(this.data[itemIndex + 3]);
        this.data[itemIndex + 4] && workRows[i].push(this.data[itemIndex + 4]);
      }
      else { // odd row
        workRows[i] = [];
        this.data[itemIndex] && workRows[i].push(this.data[itemIndex]);
        this.data[itemIndex + 1] && workRows[i].push(this.data[itemIndex + 1]);
        this.data[itemIndex + 2] && workRows[i].push(this.data[itemIndex + 2]);
      }

      if ((i + 1) % 2 === 0) itemIndex += 5;
    }

    workRows.forEach(row => this.workRows.push(row));
  }

  populateRows() {
    this.workRows.forEach(row => {
      const rowEl = document.createElement('div');
      rowEl.classList.add('g-works__row');

      row.forEach(item => {
        const itemObj = new WorksItem(item, this.baseUrl, this.win, this.isEN);
        const itemEl = itemObj.render();
        rowEl.appendChild(itemEl);
      });

      if (rowEl.hasChildNodes()) {
        this.DOM.container.appendChild(rowEl);

        setTimeout(() => {
          rowEl.childNodes.forEach(item => { item.style.opacity = 1; });
        }, 100);
      }
    });
  }

  populateCategories() {
    if (!this.DOM.category) return;
    this.win.isMobile ? this.DOM.category.classList.add('is-label-visible') : this.DOM.category.classList.add('is-visible');

    this.DOM.categoryList = this.DOM.category.querySelector('.category__list');

    for (let cat in this.categoryData) {
      const categoryItem = document.createElement('li');
      categoryItem.classList.add('category__item');

      const categoryLink = document.createElement('a');
      categoryLink.setAttribute('href', `#${cat}`);
      categoryLink.innerHTML = this.categoryData[cat];

      categoryLink.addEventListener('click', this.onCategoryClick.bind(this));

      categoryItem.appendChild(categoryLink);
      this.DOM.categoryList.appendChild(categoryItem);
    }
  }

  onCategoryClick(e) {
    e.preventDefault();
    const catNumber = e.currentTarget.getAttribute('href').replace('#', '');

    if (this.currentCategory === catNumber) return;

    this.DOM.container.innerHTML = '';

    this.nextPage = 1;
    this.currentCategory = catNumber;

    Utils.sendRequest(`${this.apiURL}?page=${this.nextPage}&category=${this.currentCategory}`, xhr => {
      const resp = JSON.parse(xhr.response);

      if (!resp.status) return;

      this.data = resp.works;
      this.nextPage = resp.next && resp.next;
      this.workRows = [];

      this.handleGetData();
    });
  }

  onMoreClick(e) {
    e.preventDefault();
    if (!this.nextPage) return;

    Utils.sendRequest(`${this.apiURL}?page=${this.nextPage}&category=${this.currentCategory}`, xhr => {
      const resp = JSON.parse(xhr.response);

      if (resp.status === true) {
        this.nextPage = resp.next;
        this.data = resp.works;
        this.workRows = [];

        this.handleGetData();
      }
    });
  }
}