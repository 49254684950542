import Utils from './Utils';

export default class MembersController {
  constructor(baseUrl, isEN) {
    this.DOM = {
      el: document.querySelector('[data-members="el"]')
    };

    this.baseUrl = baseUrl;
    this.isEN = isEN;

    this.apiURL = this.baseUrl + '/api/v1/members';
    this.data = {};
  }

  init() {
    if (!this.DOM.el) return;
    this.getChildren();
    this.getInitialData();
  }

  getChildren() {
    this.DOM.container = this.DOM.el.querySelector('.members__list');
  }

  getInitialData() {
    Utils.sendRequest(this.apiURL, xhr => {
      const resp = JSON.parse(xhr.response);

      if (resp.status !== 'success') return;

      this.data = resp.member;

      this.populateList();
    });
  }

  renderItem(data) {
    const itemEl = document.createElement('li');
    itemEl.classList.add('members__item');

    const linkEl = document.createElement('a');
    linkEl.classList.add('members__link');
    linkEl.setAttribute('href', this.isEN ? data.en_url : data.ja_url);

    const imageboxEl = document.createElement('figure');
    imageboxEl.classList.add('members__imagebox');

    const frontImageEl = document.createElement('div');
    frontImageEl.classList.add('members__image', 'members__image--front');
    frontImageEl.style.backgroundImage = `url(${data.image1.indexOf(this.baseUrl) > -1 ? data.image1 : this.baseUrl + data.image1})`;

    const backImageEl = document.createElement('div');
    backImageEl.classList.add('members__image', 'members__image--back');
    backImageEl.style.backgroundImage = `url(${data.image2.indexOf(this.baseUrl) > -1 ? data.image2 : this.baseUrl + data.image2})`;

    const textboxEl = document.createElement('div');
    textboxEl.classList.add('members__textbox');

    const nameEl = document.createElement('h2');
    nameEl.classList.add('members__name');
    nameEl.innerHTML = this.isEN ? data.en_name : data.ja_name;

    const positionEl = document.createElement('p');
    positionEl.classList.add('members__position');
    positionEl.innerHTML = this.isEN ? data.en_position : data.ja_position;

    const taglistEl = document.createElement('ul');
    taglistEl.classList.add('members__taglist');

    data[this.isEN ? 'en_specialties' : 'ja_specialties'].forEach(spec => {
      const specialItemEl = document.createElement('li');
      specialItemEl.classList.add('members__tagitem');
      specialItemEl.innerHTML = spec;
      taglistEl.appendChild(specialItemEl);
    });

    imageboxEl.appendChild(frontImageEl);
    imageboxEl.appendChild(backImageEl);
    textboxEl.appendChild(nameEl);
    textboxEl.appendChild(positionEl);
    textboxEl.appendChild(taglistEl);
    linkEl.appendChild(imageboxEl);
    linkEl.appendChild(textboxEl);
    itemEl.appendChild(linkEl);

    // itemEl.style.transition = 'opacity 500ms';
    // itemEl.style.opacity = 0;

    return itemEl;
  }

  populateList() {
    this.data.forEach(data => {
      const itemEl = this.renderItem(data);
      this.DOM.container.appendChild(itemEl);
    });

    setTimeout(() => {
      this.DOM.container.childNodes.forEach(el => {
        if (el.style) {
          el.style.opacity = 1;
        }
      });
    }, 100);
  }

  populateYears() {
    this.data.year.forEach(year => {
      const optionEl = document.createElement('option');
      optionEl.setAttribute('value', year);
      optionEl.innerText = year;

      this.DOM.select.appendChild(optionEl);
    });
  }

  onMoreClick(e) {
    e.preventDefault();
    if (!this.nextPage) return;

    this.getData();
  }

  onSelectChange(e) {
    this.currentYear = e.currentTarget.value;
    this.nextPage = 1;

    this.DOM.container.innerHTML = '';

    this.getData();
  }
}