export default class DetailNav {
  constructor() {
    this.DOM = {
      el: document.querySelector('[data-detail-nav="el"]')
    };
  }

  init() {
    if (!this.DOM.el) return;
    this.getChildren();
    this.bindEvents();
  }

  getChildren() {
    this.DOM.link = this.DOM.el.querySelector('.g-detail-nav__link a');
  }

  bindEvents() {
    this.DOM.link.addEventListener('mouseover', this.onMouseOver.bind(this));
    this.DOM.link.addEventListener('mouseleave', this.onMouseLeave.bind(this));
  }

  onMouseOver() {
    this.DOM.el.classList.add('is-hover');
  }

  onMouseLeave() {
    this.DOM.el.classList.remove('is-hover');
  }
}