export default class Header {
  constructor(isEN) {
    this.DOM = {
      el: document.querySelector('[data-header="el"]')
    };

    this.isEN = isEN;
  }

  init() {
    if (!this.DOM.el) return;
    this.getChildren();
    this.bindEvents();
    this.handlePageChange(this.isEN);
  }

  getChildren() {
    this.triggerEl = this.DOM.el.querySelector('.g-header__trigger a');
    this.navLinkEls = Array.from(this.DOM.el.querySelectorAll('.g-header__nav-item a'));
  }

  bindEvents() {
    this.triggerEl.addEventListener('click', this.onTriggerClick.bind(this));
    this.navLinkEls.forEach(el => el.addEventListener('mouseenter', this.onLinkEnter));
    this.navLinkEls.forEach(el => el.addEventListener('mouseleave', this.onLinkLeave));
  }

  handleWindowLoad() {
    setTimeout(() => this.DOM.el.classList.add('is-anim-over'), 3500);
  }

  onTriggerClick(e) {
    e.preventDefault();
    this.DOM.el.classList.toggle('is-open');
  }

  onLinkEnter(e) {
    e.currentTarget.classList.add('mouse-enter');
  }

  onLinkLeave(e) {
    const targetEl = e.currentTarget;
    targetEl.classList.add('mouse-leave');
    setTimeout(() => targetEl.classList.remove('mouse-enter', 'mouse-leave'), 300);
  }

  handlePageChange(isEN) {
    this.DOM.el.classList.remove('is-open');
    let activeURL;

    if (isEN) {
      if (window.location.pathname.split('/')[2]) {
        activeURL = window.location.pathname.split('/')[2].replace(/\//g, '');
      }
    }
    else {
      activeURL = window.location.pathname.split('/')[1].replace(/\//g, '');
    }

    this.navLinkEls
      .filter(el => !el.parentNode.classList.contains('g-header__nav-item--last'))
      .forEach(el => {
      el.classList.remove('is-active');

      if (el.getAttribute('href')
          .replace('/en/', '')
          .replace(/#.*/g, '')
          .replace(/\//g, '') === activeURL) {
        el.classList.add('is-active');
      }
    });
  }
}