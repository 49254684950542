export default class WorksItem {
  constructor(data, urlBase, win, isEN) {
    this.data = data;
    this.urlBase = urlBase;
    this.win = win;
    this.isEN = isEN;
  }

  render() {
    const itemEl = document.createElement('div');
    itemEl.classList.add('g-works__item');
    itemEl.style.transition = 'opacity 500ms';
    itemEl.style.opacity = 0;

    const linkEl = document.createElement('a');
    linkEl.classList.add('g-works__link');
    linkEl.setAttribute('href', this.isEN ? this.data.en_url : this.data.ja_url);

    const imageEl = document.createElement('figure');
    imageEl.classList.add('g-works__image');
    imageEl.style.backgroundImage = `url(${this.data.image.indexOf(this.urlBase) > -1 ? this.data.image : this.urlBase + this.data.image})`;

    const textboxEl = document.createElement('div');
    textboxEl.classList.add('g-works__textbox');

    const titleEl = document.createElement('h2');
    titleEl.classList.add('g-works__title');

    const titleSpanEl = document.createElement('span');
    titleSpanEl.innerHTML = this.isEN ? this.data.en_text : this.data.ja_text;

    titleEl.appendChild(titleSpanEl);
    textboxEl.appendChild(titleEl);
    linkEl.appendChild(imageEl);
    linkEl.appendChild(textboxEl);
    itemEl.appendChild(linkEl);

    linkEl.addEventListener('mousemove', this.onMouseMove.bind(this));
    linkEl.addEventListener('mouseenter', this.onMouseEnter.bind(this));
    linkEl.addEventListener('mouseleave', this.onMouseLeave.bind(this));

    return itemEl;
  }

  onMouseEnter(e) {
    const currentEl = e.currentTarget;
    currentEl.parentElement.style.zIndex = 2;
    this.onMouseMove(e);
  }

  onMouseLeave(e) {
    const currentEl = e.currentTarget;
    currentEl.parentElement.style.zIndex = 1;
  }

  onMouseMove(e) {
    if (this.win.isMobile) return;
    const currentEl = e.currentTarget;
    const linkRect = currentEl.getBoundingClientRect();
    const textEl = currentEl.querySelector('.g-works__textbox');
    const textRect = textEl.getBoundingClientRect();

    const xPos = e.clientX - linkRect.left - (textRect.width / 2);
    const yPos = e.clientY - linkRect.top - (textRect.height / 2);

    textEl.style.transform = `translate(${xPos}px, ${yPos}px)`;
  }
}