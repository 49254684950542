import Utils from './Utils';

export default class NewsController {
  constructor(baseUrl, isEN) {
    this.DOM = {
      el: document.querySelector('[data-news="el"]')
    };

    this.baseUrl = baseUrl;
    this.isEN = isEN;
    this.apiURL = this.baseUrl + '/api/v1/news';

    this.data = {};

    this.currentPage = 1;
    this.nextPage = 1;
    this.currentYear = '';
  }

  init() {
    if (!this.DOM.el) return;
    this.getChildren();
    this.getInitialData();
    this.bindEvents();
  }

  getChildren() {
    this.DOM.select = this.DOM.el.querySelector('[data-news="select"]')
    this.DOM.container = this.DOM.el.querySelector('.news__list');
    this.DOM.more = this.DOM.el.querySelector('[data-news="more"]');
  }

  bindEvents() {
    this.DOM.more.addEventListener('click', this.onMoreClick.bind(this));
    this.DOM.select.addEventListener('change', this.onSelectChange.bind(this));
  }

  getInitialData() {
    Utils.sendRequest(`${this.apiURL}?page=${this.currentPage}&year=${this.currentYear}`, xhr => {
      const resp = JSON.parse(xhr.response);

      if (resp.status !== 'success') return;

      this.data = resp;
      this.nextPage = this.data.next;

      this.populateYears();
      this.populateList();
    });
  }

  getData() {
    Utils.sendRequest(`${this.apiURL}?page=${this.nextPage}&year=${this.currentYear}`, xhr => {
      const resp = JSON.parse(xhr.response);

      if (resp.status !== 'success') return;

      this.data = resp;
      this.nextPage = this.data.next;

      this.handleGetData();
    });
  }

  handleGetData() {
    this.populateList();
  }

  renderItem(data) {
    const itemEl = document.createElement('li');
    itemEl.classList.add('news__item');

    const linkEl = document.createElement('a');
    linkEl.classList.add('news__link');
    linkEl.setAttribute('href', this.isEN ? data.en_url : data.ja_url);

    const imageboxEl = document.createElement('div');
    imageboxEl.classList.add('news__imagebox');

    const imageEl = document.createElement('figure');
    imageEl.classList.add('news__image');
    imageEl.style.backgroundImage = `url(${data.image === '' ? '/assets/img/common/no-image.png' : data.image})`;

    const textboxEl = document.createElement('div');
    textboxEl.classList.add('news__textbox');

    const timeEl = document.createElement('time');
    timeEl.classList.add('news__date');
    timeEl.setAttribute('datetime', data.date.replaceAll('.', '-'));
    timeEl.innerText = data.date;

    const titleEl = document.createElement('h2');
    titleEl.classList.add('news__title');
    titleEl.innerHTML = this.isEN ? data.en_text : data.ja_text;

    textboxEl.appendChild(timeEl);
    textboxEl.appendChild(titleEl);
    imageboxEl.appendChild(imageEl);
    linkEl.appendChild(imageboxEl);
    linkEl.appendChild(textboxEl);
    itemEl.appendChild(linkEl);

    itemEl.style.transition = 'opacity 500ms';
    itemEl.style.opacity = 0;

    return itemEl;
  }

  populateList() {
    this.data.news.forEach(data => {
      const itemEl = this.renderItem(data);
      this.DOM.container.appendChild(itemEl);
    });

    setTimeout(() => {
      this.DOM.container.childNodes.forEach(el => {
        if (el.style) {
          el.style.opacity = 1;
        }
      });
    }, 100);
  }

  populateYears() {
    this.data.year.forEach(year => {
      const optionEl = document.createElement('option');
      optionEl.setAttribute('value', year);
      optionEl.innerText = year;

      this.DOM.select.appendChild(optionEl);
    });
  }

  onMoreClick(e) {
    e.preventDefault();
    if (!this.nextPage) return;

    this.getData();
  }

  onSelectChange(e) {
    this.currentYear = e.currentTarget.value;
    this.nextPage = 1;

    this.DOM.container.innerHTML = '';

    this.getData();
  }
}