export default class WorksRelatedItem {
  constructor(el, win) {
    this.DOM = {
      el: el
    };

    this.win = win;

    this.init();
  }

  init() {
    if (!this.DOM.el) return;
    this.getChildren();
    this.bindEvents();
  }

  getChildren() {
    this.DOM.titleEl = this.DOM.el.querySelector('.g-related__title');
  }

  bindEvents() {
    this.DOM.el.addEventListener('mousemove', this.onMouseMove.bind(this));
    this.DOM.el.addEventListener('mouseenter', this.onMouseEnter.bind(this));
    this.DOM.el.addEventListener('mouseleave', this.onMouseLeave.bind(this));
  }

  onMouseMove(e) {
    if (this.win.isMobile) return;
    const elRect = this.DOM.el.getBoundingClientRect();
    const titleRect = this.DOM.titleEl.getBoundingClientRect();

    const xPos = e.clientX - elRect.left - (titleRect.width / 2);
    const yPos = e.clientY - elRect.top - (titleRect.height / 2);

    this.DOM.titleEl.style.transform = `translate(${xPos}px, ${yPos}px)`;
  }

  onMouseEnter() {
    this.DOM.el.style.zIndex = 2;
  }

  onMouseLeave() {
    this.DOM.el.style.zIndex = 1;
  }
}