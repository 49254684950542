import '../scss/main.scss';
import barba from '@barba/core';

import Header from './modules/Header';
import { DefaultTransition } from './modules/Transitions';

import ShowcaseController from './modules/ShowcaseController';
import WorksController from './modules/WorksController';
import WorksRelatedItem from './modules/WorksRelatedItem';
import NewsController from './modules/NewsController';
import MembersController from './modules/MembersController';

import DetailNav from './modules/DetailNav';
import GoogleMap from './modules/GoogleMap';

class App {
  constructor() {
    this.win = {
      width: window.innerWidth,
      height: window.innerHeight,
      aspectRatio: window.innerWidth / window.innerHeight,
      isMobile: window.innerWidth <= 767
    };

    this.isProd = window.location.host === 'www.drill-inc.jp' || window.location.host === 'drill-inc.jp';
    // this.baseUrl = this.isProd ? 'http://www.drill-inc.jp' : 'http://drill.dev.aid-dcc.com';
    this.baseUrl = window.location.origin;
    this.isEN = window.location.pathname.split('/')[1] === 'en';

    this.isInitialLoad = true;

    this.currentPage = document.body.getAttribute('data-page');
  }

  init() {
    this.onFirstLoad();
    this.onDocReady();
  }

  bindEvents() {
    window.onload = () => this.onWindowLoad();
    window.onresize = () => this.onResize();
  }

  onFirstLoad() {
    this.initPjax();
    this.initHeader();
    this.bindEvents();
  }

  onDocReady() {
    this.initShowcase();
    this.initWorks();
    this.initDetailNav();
    this.initMap();
    this.initNews();
    this.initMembers();
    this.initWorksRelated();
  }

  onWindowLoad() {
    document.body.classList.add('is-loaded');

    this.hidePreloadScreen();
    this.header && this.header.handleWindowLoad();

    setTimeout(() => document.body.classList.add('first-load-over'), 3000);
    this.isInitialLoad = false;
  }

  onResize() {
    this.win.width = window.innerWidth;
    this.win.height = window.innerHeight;
    this.win.aspectRatio = window.innerWidth / window.innerHeight;
    this.win.isMobile = window.innerWidth <= 767;
  }

  onPageChange(data) {
    //analytics
    if (typeof ga === 'function') {
      ga('set', 'page', window.location.pathname);
      ga('send', 'pageview');
    }
    // page name
    document.body.classList.remove(data.current.namespace);
    document.body.classList.add(data.next.namespace);
    document.body.setAttribute('data-page', data.next.namespace);
    this.currentPage = data.next.namespace;
    // page en
    this.isEN = window.location.pathname.split('/')[1] === 'en';
    document.body.classList.toggle('page-en', this.isEN);
    document.body.classList.add('is-loaded');

    this.onDocReady();
  }

  initPjax() {
    barba.init({
      schema: { prefix: 'data-pjax' },
      transitions: [ DefaultTransition ]
    });

    barba.hooks.leave(data => {
      document.body.classList.remove('is-loaded');
      this.header && setTimeout(() => this.header.handlePageChange(this.isEN), 500);
    });

    barba.hooks.afterLeave((data) => {});
    barba.hooks.enter(data => {});

    barba.hooks.after(data => {
      this.onPageChange(data);

      if (window.location.hash) {
        const target = document.querySelector(window.location.hash);
        target && window.scrollTo(0, target.offsetTop);
      }
      else {
        window.scrollTo(0, 0);
      }
    });
  }

  hidePreloadScreen() {
    this.preloadScreen = document.querySelector('[data-preload="el"]');

    if (!this.isInitialLoad || !this.preloadScreen) return;

    setTimeout(() => this.preloadScreen.classList.add('is-loaded'), 2000);
    setTimeout(() => document.body.classList.add('first-load-over'), 2750);
  }

  initHeader() {
    this.header = new Header(this.isEN);
    this.header.init();
  }

  initShowcase() {
    this.showcase = new ShowcaseController(this.baseUrl);
    this.showcase.init();
  }

  initWorks() {
    this.works = new WorksController(this.baseUrl, this.currentPage, this.win, this.isEN);
    this.works.init();
  }

  initNews() {
    this.news = new NewsController(this.baseUrl, this.isEN);
    this.news.init();
  }

  initMembers() {
    this.members = new MembersController(this.baseUrl, this.isEN);
    this.members.init();
  }

  initDetailNav() {
    this.detailNav = new DetailNav();
    this.detailNav.init();
  }

  initMap() {
    this.map = new GoogleMap();
    this.map.init();
  }

  initWorksRelated() {
    const relatedItems = Array.from(document.querySelectorAll('[data-works-related="el"]'));
    relatedItems.length && relatedItems.forEach(el => new WorksRelatedItem(el, this.win));
  }
}

const app = new App();
app.init();
