export const DefaultTransition = {
	name: 'default-transition',
	leave(data) {
		this.overlayEl = document.querySelector('.g-overlay');

		return new Promise(resolve => {
			this.overlayEl.classList.add('is-transition-start');
			setTimeout(() => resolve(), 500);
		});
	},

	afterEnter(data) {
		return new Promise(resolve => resolve())
		.then(() => {
			this.overlayEl.classList.add('is-transition-end');
			setTimeout(() => this.overlayEl.classList.remove('is-transition-start', 'is-transition-end'), 650);
		});
	},
};